<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{ $t('seller.breadcrumb.seller') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'marketingtools' }">{{ $t('seller.breadcrumb.marketPlatform') }}</el-breadcrumb-item>
				<template v-if="couponForm.type == 1">
					<el-breadcrumb-item :to="{ name: 'couponList' }">{{ $t('seller.breadcrumb.coupon') }}</el-breadcrumb-item>
					<el-breadcrumb-item>{{ $t('seller.breadcrumb.addShopCoupon') }}</el-breadcrumb-item>
				</template>
				<template v-else>
					<el-breadcrumb-item :to="{ name: 'couponList', query: { type: 2 } }">
						{{ $t('seller.breadcrumb.coupon') }}
					</el-breadcrumb-item>
					<el-breadcrumb-item>{{ $t('seller.breadcrumb.addGoodsCoupon') }}</el-breadcrumb-item>
				</template>
			</el-breadcrumb>
		</el-card>
		<el-form :model="couponForm" ref="couponForm" label-width="128px">
			<el-card v-if="couponForm.type == 1" class="seller-card">
				<template slot="header">{{ $t('seller.coupon.paneTitle1') }}</template>
				<el-form-item label-width="0">
					<el-radio-group v-model="couponForm.channel_type">
						<el-radio v-for="(item, index) in promoteOption" :key="index" :label="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-card>
			<el-card class="seller-card">
				<template slot="header">{{ $t('seller.coupon.paneTitle2') }}</template>
				<el-form-item :label="$t('seller.formItem.name')" prop="name"
					:rules="[{ required: true, message: $t('seller.validator.couponName'), trigger: 'blur' }]">
					<el-input v-model="couponForm.name" class="w350" :placeholder="$t('seller.placeholder.couponName')"
						maxlength="10" show-word-limit></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.useTime')" prop="use_end_time"
					:rules="[{ required: true, validator: validateTimes, trigger: 'blur' }]">
					<el-date-picker v-model="datetimes" type="daterange" range-separator="-"
						:start-placeholder="$t('seller.placeholder.originDate')" value-format="timestamp"
						:end-placeholder="$t('seller.placeholder.endDate')" :picker-options="startPickerOptions">
					</el-date-picker>
					<span class="tips"><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips1') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.cheapRemind')" prop="price_remind"
					:rules="[{ required: true, message: $t('seller.validator.cheapRemind'), trigger: 'blur' }]">
					<div>
						<span class="group-before">{{ $t('seller.coupon.paneText2-1') }}</span>
						<el-input-number v-model="couponForm.price_remind" class="w90" controls-position="right" :min="1"
							:max="10"></el-input-number>
						<span class="group-after">{{ $t('seller.coupon.paneText2-2') }}</span>
					</div>
					<div class="notice">
						<span class="tips"><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips2') }}</span>
						<!-- <a href="#">详情了解更多></a> -->
					</div>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.activityTarget')">
					<el-radio-group v-model="couponForm.activity_target">
						<el-radio v-for="(item, index) in activityTargetOption" :key="index" :label="item.value">{{
							item.label }}</el-radio>
					</el-radio-group>
					<span class="tips"><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips3') }}</span>
				</el-form-item>
				<!-- <el-form-item :label="$t('seller.formItem.expireRemind')">
					<el-checkbox v-model="couponForm.expire_reminder" true-label="1" false-label="0">
					</el-checkbox>
				</el-form-item> -->
				<el-form-item :label="$t('seller.formItem.goodsRange')" v-if="couponForm.type == 2" prop="goods_ids"
					:rules="[{ required: true, message: $t('seller.validator.selectGoods'), trigger: 'blur' }]">
					<div>
						<el-button type="primary" @click="handleGoodsShow">{{ $t('seller.actions.selectGoods') }}</el-button>
						<span>（{{ $t('seller.actions.selected') }}{{ couponForm.goods_ids.length }}{{ $t('seller.unit.pcs') }}）</span>
					</div>
					<p>{{ $t('seller.coupon.paneTips4') }}</p>
				</el-form-item>
			</el-card>
			<el-card v-for="item, index in couponForm.other_info" :key="index" class="seller-card">
				<div slot="header" class="card-head">
					<span v-if="couponForm.type == 1">{{ $t('seller.coupon.paneTitle4') }}{{ index + 1 }}</span>
					<span v-else>{{ $t('seller.coupon.paneTitle3') }}</span>
					<div class="actions" v-if="couponForm.type == 1">
						<el-button type="text" icon="el-icon-plus" @click="handleDenominationAdd">{{
							$t('seller.actions.addNewDenomination') }}</el-button>
						<el-button type="text" icon="el-icon-minus" :disabled="couponForm.other_info.length < 2"
							@click="handleDenominationDel(index)"> {{ $t('seller.actions.deleteDenomination') }}</el-button>
					</div>
				</div>
				<el-form-item :label="$t('seller.formItem.couponAmount')" :prop="'other_info.' + index + '.money'"
					:rules="{ required: true, validator: validateMoney, trigger: 'blur' }">
					<el-input v-model="item.money" class="w350" :placeholder="$t('seller.placeholder.couponAmount')"
						oninput="value=value.replace(/[^\d]/g,'')"></el-input>
					<span class="group-after">{{ $t('seller.unit.yuan') }}</span>
					<span class="tips"><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips5') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.useThreshold')" :prop="'other_info.' + index + '.quota'"
					:rules="{ required: true, validator: validateQuota, trigger: 'blur' }">
					<span class="group-before">{{ $t('seller.unit.full') }}</span>
					<el-input v-model="item.quota" class="w326" :placeholder="$t('seller.placeholder.useThreshold')"
						oninput="value=value.replace(/[^\d]/g,'')"></el-input>
					<span class="group-after">{{ $t('seller.unit.yuan') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.circulation')" :prop="'other_info.' + index + '.give_num'"
					:rules="{ required: true, validator: validateGiveNum, trigger: 'blur' }">
					<el-input v-model="item.give_num" class="w350"
						:placeholder="couponForm.channel_type | typeFilter($t('seller.filters.circulation'))"
						oninput="value=value.replace(/[^\d]/g,'')">
					</el-input>
					<span class="group-after">{{ $t('seller.unit.sheet') }}</span>
					<span class="tips"><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips6') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.limitPerPerson')"
					:prop="'other_info.' + index + '.limit_give_num'"
					:rules="{ required: true, message: $t('seller.validator.circulation'), trigger: 'change' }">
					<el-select v-model="item.limit_give_num">
						<el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
						<el-option :label="$t('seller.coupon.unlimited')" :value="0"></el-option>
					</el-select>
					<span class="group-after">{{ $t('seller.unit.sheet') }}</span>
				</el-form-item>
			</el-card>
		</el-form>

		<div class="page-bottom">
			<el-button @click="handleCouponSubmit" type="primary">{{ $t('seller.actions.riskCheck') }}</el-button>
		</div>

		<el-dialog :title="$t('seller.dialogTitle.goods')" width="960px" :visible.sync="dialogVisibleGoods"
			:before-close="handleCloseGoods" custom-class="seller-dialog">
			<el-tabs v-model="activeName" type="card" clearable @tab-click="handleClickGoods">
				<el-tab-pane v-for="(item, index) in dialogGoodsNav" :key="index" :label="item.label"
					:name="item.value"></el-tab-pane>
			</el-tabs>
			<el-scrollbar class="seller-dialog-scrollbar">
				<el-form v-if="activeName == 0" size="mini" inline>
					<el-form-item>
						<el-select v-model="screenForm.store_cate_id" clearable @change="handleSeach">
							<el-option v-for="(item, index) in shopCateOption" :key="index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.goods_name" clearable
							:placeholder="$t('seller.placeholder.babyName')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.goods_code" clearable
							:placeholder="$t('seller.placeholder.babyCode')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.goods_id" clearable
							:placeholder="$t('seller.placeholder.babyId')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSeach">{{ $t('seller.actions.search') }}</el-button>
					</el-form-item>
				</el-form>
				<el-table v-loading="lisLoading" ref="goodsTable" :data="goodsList" size="small"
					:row-key="getRowKey" @selection-change="handleSelectionChange" class="seller-table">
					<el-table-column type="selection" :reserve-selection="true" width="45">
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.babyDescribe')" min-width="220px">
						<template slot-scope="scope">
							<div class="goods-item">
								<img class="pic" :src="scope.row.default_image" alt="">
								<div class="item-mian">
									<div class="title">{{ scope.row.goods_name }}</div>
									<div class="number">
										{{ $t('seller.coupon.goodsCode') + ':' }}{{ scope.row.goods_code ?
											scope.row.goods_code : '-' }}
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.unitPrice')">
						<template slot-scope="scope">￥{{ scope.row.price }}</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.stock')" prop="stock"></el-table-column>
					<el-table-column :label="'30' + $t('seller.unit.day') + $t('seller.tableHead.totalSales')"
						prop="sales">
						<template slot-scope="scope">{{ scope.row.sales ? scope.row.sales : '0' }}</template>
					</el-table-column>
				</el-table>
				<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
					@pagination="getGoodsList"></pagination>
			</el-scrollbar>
			<div slot="footer">
				<el-button size="small" @click="handleCloseGoods">{{ $t('seller.actions.cancel') }}</el-button>
				<el-button type="primary" size="small" @click="handleToggleSelecting">{{ $t('seller.actions.confirm') }}</el-button>
			</div>
		</el-dialog>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { getCouponGoods, createCoupon } from '@/api/seller/coupon';
export default {
	components: { Pagination },
	data() {
		return {
			activityTargetOption: this.$t('seller.coupon.activityTargetOption'),
			couponForm: {
				name: '',
				use_begin_time: '',
				use_end_time: '',
				expire_reminder: '0',
				activity_target: '0',
				price_remind: '',
				type: this.$route.query.type ? this.$route.query.type : '1',
				channel_type: this.$route.query.channel || 0,
				goods_ids: [],
				other_info: [{
					give_num: '',
					limit_give_num: '',
					money: '',
					quota: ''
				}],
			},
			promoteOption: this.$t('seller.coupon.promoteOption'),
			datetimes: '',
			startPickerOptions: {
				disabledDate: (time) => {
					return time.getTime() < new Date(new Date().getTime() - 86400000)
				}
			},
			dialogVisibleGoods: false,
			activeName: '0',
			dialogGoodsNav: this.$t('seller.coupon.dialogGoodsNav'),
			shopCateOption: this.$t('seller.coupon.shopCateOption'),
			lisLoading: false,
			pageCurr: 1,
			pageSize: 5,
			pageTotal: 0,
			goodsList: [],
			checkGoods: [],
			multipleSelection: [],
			screenForm: {
				goods_code: '',
				goods_id: '',
				goods_name: '',
				store_cate_id: '0',
			},
		}
	},
	created() {
		this.getGoodsList();
	},
	methods: {
		validateTimes(rule, value, callback) {
			if (!this.datetimes) {
				callback(new Error(this.$t('seller.validator.datetime')));
			} else {
				let datetime = Date.now() / 1000;
				let start_time = this.datetimes[0] / 1000;
				let end_time = this.datetimes[1] / 1000;
				if ((start_time - datetime) / 86400 > 60) {
					callback(new Error(this.$t('seller.validator.advanceDay60')));
				} else if ((end_time - start_time) / 86400 > 60) {
					callback(new Error(this.$t('seller.validator.validityDay60')));
				} else {
					callback();
				}
			}
		},
		validateMoney(rule, value, callback) {
			if (value === '') {
				callback(new Error(this.$t('seller.validator.couponAmount')));
			} else if (parseInt(value) < 1) {
				callback(new Error(this.$t('seller.validator.denominationMin')));
			} else if (parseInt(value) > 5000) {
				callback(new Error(this.$t('seller.validator.denominationMax')));
			} else {
				callback();
			}
		},
		validateQuota(rule, value, callback) {
			if (value === '') {
				callback(new Error(this.$t('seller.validator.thresholdAmount')));
			} else {
				let param = rule.field.split('.');
				let item = this.couponForm[param[0]][param[1]];
				if (parseFloat(item.money) >= parseFloat(value)) {
					callback(new Error(this.$t('seller.validator.thresholdHigher')));
				} else {
					callback();
				}
			}
		},
		validateGiveNum(rule, value, callback) {
			if (value === '') {
				callback(new Error(this.$t('seller.validator.circulation')));
			} else if (this.couponForm.channel_type === 0 && parseInt(value) < 1000) {
				callback(new Error(this.$t('seller.validator.least1000')));
			} else if (this.couponForm.channel_type === 1 && parseInt(value) < 1) {
				callback(new Error(this.$t('seller.validator.least1')));
			} else if (parseInt(value) > 100000) {
				callback(new Error(this.$t('seller.validator.most1w')));
			} else {
				callback();
			}
		},
		getGoodsList() {
			this.lisLoading = true
			let param = {
				...this.screenForm,
				type: this.activeName,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			};
			getCouponGoods(param).then(response => {
				this.goodsList = response.data.list;
				this.pageTotal = parseInt(response.data.total);
				this.lisLoading = false
			})
		},
		handleSeach(val) {
			this.pageCurr = 1;
			this.getGoodsList();
		},
		handleGoodsShow() {
			this.dialogVisibleGoods = true;
		},
		handleCloseGoods() {
			this.dialogVisibleGoods = false;
		},
		handleClickGoods() {
			this.pageCurr = 1;
			this.getGoodsList();
		},
		getRowKey(row) {
			return row.goods_id
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		handleToggleSelecting() {
			let ids = [];
			this.multipleSelection.forEach(item => {
				ids.push(item.goods_id);
			})
			this.checkGoods = JSON.parse(JSON.stringify(this.multipleSelection));
			this.couponForm.goods_ids = ids;
			this.dialogVisibleGoods = false;
		},
		handleDenominationAdd() {
			if (this.couponForm.other_info.length < 3) {
				let item = {
					give_num: '',
					limit_give_num: '',
					money: '',
					quota: ''
				}
				this.couponForm.other_info.push(item);
			}
		},
		handleDenominationDel(index) {
			if (this.couponForm.other_info.length > 1) {
				this.couponForm.other_info.splice(index, 1);
			} else {
				return false;
			}
		},
		handleCouponSubmit() {
			if (this.datetimes) {
				this.couponForm.use_begin_time = this.datetimes[0] / 1000;
				this.couponForm.use_end_time = this.datetimes[1] / 1000;
			}
			this.$refs.couponForm.validate((valid) => {
				if (valid) {
					let loading = this.$loading();
					const params = {
						...this.couponForm
					}
					params.goods_ids = params.goods_ids.join(',')
					params.other_info = JSON.stringify(params.other_info)
					createCoupon(params).then(() => {
						loading.close();
						this.$message.success(this.$t('seller.successMsg.add'));
						this.$router.push({
							name: 'couponList',
							query: {
								type: this.couponForm.type
							}
						});
					}).catch(() => {
						loading.close();
					})
				} else {
					return false;
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.card-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.el-form-item {
		&:last-child {
			margin-bottom: 0;
		}
	}

	.w90 {
		width: 90px;
	}

	.w326 {
		width: 326px;
	}

	.w350 {
		width: 350px;
	}

	.tips {
		margin-left: 40px;

		.el-icon-warning {
			font-size: 16px;
			color: #ff7a45;
			margin-right: 4px;
		}
	}

	.group-before {
		color: #666666;
		padding-right: 10px;
	}

	.group-after {
		color: #666666;
		padding-left: 10px;
	}

	.notice {
		.tips {
			margin-left: 0;
		}

		a {
			color: $--seller-primary;
		}
	}
}

.page-bottom {
	margin-bottom: 20px;
}

.seller-dialog-scrollbar {
	height: 520px;
	.goods-item {
		display: flex;
		.pic {
			width: 60px;
			height: 60px;
			object-fit: cover;
		}

		.item-mian {
			flex: 1;
			width: 0;
			margin-left: 10px;
			line-height: 17px;

			.title {
				height: 34px;
				font-size: 14px;
				color: #666666;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.number {
				height: 17px;
				margin-top: 9px;
				color: #333333;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>
